<template>
  <div class="popup-body" v-if="planInfo">
    <div class="align-left" :class="planInfo.key">
      <img src="@/assets/payment/popup-img.svg"/>
    </div>
    <div class="align-right">
      <div class="price-wrapper">
        <h3>{{planInfo.name}}</h3>
        <div>
          <span class="price" v-if="planInfo && planInfo.price && planInfo.price.unit_amount">
            {{formatCurrency(planInfo.price.unit_amount[currency], currency)}}</span>
          <template v-if="!planInfo.price.unit_label || planInfo.price.unit_label === ''">
            <span v-if="planInfo.type === 'association_package'"
            class="unit-label">Per month when billed annually</span>
            <span v-else class="unit-label">For entire tournament</span>
          </template>
          <span v-else class="unit-label">{{planInfo.price.unit_label}}</span>
          <a v-if="!isPayment" :href="'/v5/user/project/review/?package='+planInfo.key+'&step=ReviewPlan'"
            class="buy-package-btn">Buy</a>
        </div>
      </div>
      <rz-markdown v-if="planInfo.description" class="desc-content" :text="planInfo.description"></rz-markdown>
      <div class="wrapper">
        <div>
          <p v-if="removeEndpoints">
            <span>Accessible Endpoints</span>
            <ul v-for="plan in allowed_endpoints" :key="plan.key">
              <template v-if="endpoints[plan].link !== null">
                <li>
                  <a :href="endpoints[plan].link" target="_blank">
                    {{
                      endpoints[plan].name ? endpoints[plan].name: plan.split('-').join(' ')
                    }} API
                  </a>
                </li>
              </template>
              <template v-else>
                <li>{{plan.split('-').join(' ')}} API</li>
              </template>
            </ul>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.popup-body {
  display: flex;
  .align-left {
    position: relative;
    width: 100%;
    max-width: size(280);
    display: none;
    background: #93E8EB;
    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
    img {
      width: size(240);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &.rs_package_icc_wc_t20_2022_6f3, &.rs_package_icc_wc_t20_2022_177 {
      background: #A9C6FC;
    }
    &.rs_package_iplt20_2022_689 {
      background: #93E8EB;
    }
    &.rs_package_c__board__icc__c2ab7ee61_1ad, &.rs_package_c__board__icc__c2ab7ee61_72a  {
      background: #6FCAF2;
    }
  }
  .align-right {
    width: 100%;
    padding: size(25);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(60) size(40) size(50);
    }
    p {
      font-size: size(12);
      line-height: size(17);
      padding-bottom: size(10);
      span {
        font-weight: bold;
        text-transform: capitalize;
      }
      &:last-child {
        padding-bottom: size(15);
      }
    }
    .wrapper {
      overflow-y: scroll;
      max-height: size(450);
    }
    .price-wrapper {
      padding-bottom: size(20);
      border-bottom: size(1) solid #E4E4E4;
      margin-bottom: size(20);
      h3 {
        display: inline-block;
        font-weight: 400;
        font-size: size(16);
        line-height: size(24);
        color: var(--rs-black-color);
        max-width: size(130);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
      }
      &::after {
        content: '';
        clear: both;
        display: block;
      }
      div {
        padding-top: size(10);
        @media screen and (min-width: $breakpoint-md) {
          padding-top: 0;
          float: right;
          text-align: right;
        }
        span {
          &.price {
            display: block;
            font-weight: 700;
            font-size: size(16);
            line-height: size(24);
            color: var(--rs-black-color);
            @media screen and (min-width: $breakpoint-md) {
              font-size: size(24);
              line-height: size(34);
            }
          }
          &.unit-label {
            font-size: size(12);
            line-height: size(17);
            color: #777;
            font-weight: 400;
            display: block;
            max-width: size(140);
            padding-top: size(4);
          }
        }
      }
    }
    ul {
      padding-left: size(15);
      li {
        list-style-type: disc;
        font-size: size(12);
        line-height: size(17);
        padding-top: size(6);
        text-transform: capitalize;
        a {
          &:hover {
            text-decoration: underline;
            color: var(--rs-black-color);
          }
        }
      }
    }
    .buy-package-btn {
      text-align: center;
      width: size(120);
      background: var(--hero-text);
      border-radius: size(2);
      font-size: size(12);
      line-height: size(15);
      padding: size(12) 0;
      font-weight: 700;
      display: block;
      margin: size(10) 0 0;
      color: var(--rs-white-color);
      @media screen and (min-width: $breakpoint-md) {
        margin-left: size(10);
      }
      @media screen and (min-width: $breakpoint-lg) {
        margin-left: size(20);
      }
      &:hover {
        @include jump-up-animation;
      }
    }
  }
}
</style>
<style lang="scss">
  .popup-body {
    .markdown {
      display: inline-block;
      .p {
        font-size: size(12);
        line-height: size(17);
        padding-bottom: size(10);
        color: #000000;
      }
      em {
        font-style: italic;
        font-weight: normal;
      }
      strong {
        font-weight: bolder;
        em {
          font-weight: bolder;
        }
      }
      ul {
        list-style-type: disc;
        padding-left: size(20);
        padding-bottom: size(15);
        text-transform: capitalize;
        li {
          font-size: size(12);
          line-height: size(17);
          padding-top: size(6);
          color: #000000;
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
  }
</style>

<script>
import endpoints from '../../stories/ResourcePage/Endpoints.json';

export default {
  props: {
    planInfo: Object,
    currency: String,
    isPayment: Boolean,
  },
  data() {
    return {
      endpoints,
      allowed_endpoints: [],
    };
  },
  computed: {
    removeEndpoints() {
      let endpoint = this.planInfo.allowed_endpoints;
      if (endpoint.includes('auth')) {
        endpoint = endpoint.filter((val) => val !== 'auth');
      } if (endpoint.includes('graphql')) {
        endpoint = endpoint.filter((val) => val !== 'graphql');
      } if (endpoint.includes('match-subscribe') && endpoint.includes('match-unsubscribe')) {
        endpoint = endpoint.filter((val) => (val !== 'match-subscribe' && val !== 'match-unsubscribe'));
        endpoint.push('match-via-push');
      } if ((endpoint.includes('fantasy-match-points-subscribe')
          && endpoint.includes('fantasy-match-points-unsubscribe'))) {
        endpoint = endpoint.filter((val) => (val !== 'fantasy-match-points-unsubscribe'
          && val !== 'fantasy-match-points-subscribe'));
        endpoint.push('fantasy-match-points-via-push');
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.allowed_endpoints = endpoint;
      return endpoint;
    },
  },
};
</script>

<template>
  <div class="error-page coming-soon">
    <div class="image-area">
      <div class="image-item">
        <img src="@/assets/artwork-coming-soon-bg.png" alt="Artwork">
      </div>
    </div>
    <div class="desc-area">
      <h1>{{$route.params.page | capitalize | removeHyphen}}</h1>
      <p>
        This page is still under construction! We're handcrafting it to serve
        you the best experience! Sit tight, and we will be with you soon!
      </p>
    </div>
    <section class="page-related-api" v-if="recommendedApiLinks && recommendedApiLinks.length">
      <p>In the meantime, don't forget to check out out other endpoints...</p>
      <ul>
        <li
          v-for="(link, i) in recommendedApiLinks"
          :key="i">
          <rz-link-item :linkClass="'btn rounded smaller-on-mobile'" :link="link" />
        </li>
      </ul>
    </section>
    <section class="pull-left page-support-section">
      <SupportSection />
    </section>
  </div>
</template>

<script>
import '@/assets/styles/views/error_page.scss';

import { allMetaMixins } from '@/mixin/meta';
import { mapState } from 'vuex';

import SupportSection from '@/components/SupportSection.vue';

export default {
  mixins: allMetaMixins(),
  title() {
    return 'Not ready';
  },
  components: {
    SupportSection,
  },
  computed: {
    ...mapState({
      recommendedApiLinks: (state) => state.link.cricketRecommendedApis.links,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    this.fetchData();
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    removeHyphen(value) {
      if (!value) return '';
      value = value.toString();
      return value.split('-').join(' ');
    },
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('link/fetchCricketRecommendedApis').then(() => {
      }).catch((err) => {
        console.log('Error on Recommended Apis Data', err.message);
      });
    },
  },
};
</script>
